import { FC, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as DROPZONE from 'dropzone';
import { t } from 'i18next';

export interface DropzoneProps {
  url?: string;
  token?: string;
  className?: string;
  titleHead?: string;
  text?: string;
  acceptedFiles?: string;
  maxFilesize?: number;
  parallelUploads?: number;
  paramName?: string;
  onFileUploaded?: Function;
  fileAdded?: Function;
  uploadProgress?: Function;
  fileRemoved?: Function;
  autoProcessQueue?: boolean;
  previews: any;
}

export const Dropzone: FC<DropzoneProps> = (props: any) => {
  const dropzoneRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const url = `${props.url ? props.url : '/'}`;

  useEffect(() => {
    try {
      // we make a dynamic import for the Dropzone, as this component is not made to work on SSR
      const Dropzone = DROPZONE.default;
      setLoaded(true);
      Dropzone.autoDiscover = false;
      if (dropzoneRef.current) {
        const dropzone = new Dropzone(dropzoneRef.current, {
          headers: {
            Authorization: `Bearer ${props.token}`
          },
          url: `${url}`,
          disablePreviews: true,
          createImageThumbnails: false,
          maxFilesize: props.maxFilesize || 256,
          acceptedFiles: props.acceptedFiles || '*',
          parallelUploads: props.parallelUploads || 2,
          paramName: props.paramName || 'files',
          autoProcessQueue:
            typeof props.autoProcessQueue === 'boolean' ? props.autoProcessQueue : true
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !loaded && dropzone;

        dropzone.on('complete', props.onFileUploaded);
        dropzone.on('addedfile', props.fileAdded);
        dropzone.on('uploadprogress', props.uploadProgress);
        dropzone.on('removedfile', props.fileRemoved);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <>
      <div ref={dropzoneRef} className={`dropzone ${props.className ? props.className : ''}`}>
        <div className="dz-message d-block">
          <p>{props.titleHead ? props.titleHead : t('storybook.dropzone.title')}</p>
          <p>
            <span className="note">{props.text ? props.text : t('storybook.dropzone.text')}</span>
          </p>
        </div>
      </div>
      {props.previews && (
        <div className="dz-previews">
          {typeof props.previews === 'function' ? props.previews() : props.previews}
        </div>
      )}
    </>
  );
};

Dropzone.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  className: PropTypes.string,
  titleHead: PropTypes.string,
  text: PropTypes.string,
  acceptedFiles: PropTypes.string,
  maxFilesize: PropTypes.number,
  parallelUploads: PropTypes.number,
  paramName: PropTypes.string,
  onFileUploaded: PropTypes.func,
  fileAdded: PropTypes.func,
  uploadProgress: PropTypes.func,
  fileRemoved: PropTypes.func,
  autoProcessQueue: PropTypes.bool,
  previews: PropTypes.any
};

Dropzone.defaultProps = {
  titleHead: '',
  text: ''
};
