import React from 'react';
import { Form } from 'react-bootstrap';
import { PageNumber } from './DataTable';

export interface IPerPageProps {
  perPageOptions?: Array<PageNumber>;
  labelPage: string;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
}

export const PerPage: React.FC<IPerPageProps> = ({
  perPageOptions,
  labelPage,
  pageSize,
  setPageSize
}) => {
  return (
    <div className="d-inline-block">
      <span className="text-xs">{labelPage}</span>
      <Form.Select
        size="sm"
        className="d-inline w-auto me-1"
        onChange={(e) => setPageSize(Number(e.target.value))}
        value={pageSize}
      >
        {perPageOptions?.map((option: any) => (
          <option key={option.label}>{option.label}</option>
        ))}
      </Form.Select>
    </div>
  );
};
