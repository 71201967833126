export const TimelineConfig = {
  timelinePadding: 40,
  versionOffset: 20,
  versionStep: 60,
  titleWidth: 80,
  viewBoxAspectRation:2
}

export const TimelineActionConfig = {
  heightAction: 10, 
  heightPadding: 8, 
  widthPadding: 10,
  PointerHeigth : 12
}

export const gridConfig = {
  color: "lightgrey",
  textColor: "grey",
  fontSize: "5px"
}