import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

export function Pill({ data, icon, color, className, fullHeight }: any) {
  return (
    <div className={`card-widget ${className ? className : ''} ${fullHeight ? 'h-100' : ''}`}>
      <div className="card-widget-body">
        <div className={`dot me-3 bg-${color}`}></div>
        <div className="text">
          <h6 className="mb-0">{data.name}</h6>
          <span className={`text-gray-500`}>{data.content}</span>
        </div>
      </div>

      <div className={`icon text-white bg-${color}`}>
        <FontAwesomeIcon icon={icon} />
      </div>
    </div>
  );
}

Pill.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string
  }),
  color: PropTypes.string,
  icon: PropTypes.any,
  className: PropTypes.string
};

Pill.defaultProps = {
  data: {
    name: 'Dimitri',
    content: 'This is a content'
  },
  color: 'blue',
  className: 'bg-transparent'
};
