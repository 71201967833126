import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
// @ts-ignore
import en from './en/translations.json';
// @ts-ignore
import fr from './fr/translations.json';
import { initReactI18next } from 'react-i18next';

export const resources = {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  }
} as const;

i18n.use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    saveMissing: true,
    resources
  });