import React from 'react';

let currentLanguage = 'fr';

export const useTranslation = (translation: Object | JSON) => {
  const [language, setLanguage] = React.useState(currentLanguage);
  const languages = Object.keys(translation);

React.useEffect(() => {
    currentLanguage = language || 'fr';
  }, [language]);

const translate = (key:string) => {
  return translation?.[language]?.[key];}
  return { translate, setLanguage, language, languages };
};