import React from 'react';
import { Form } from 'react-bootstrap';

interface IGenericFilterProps {
  handleChange: (event: React.ChangeEvent) => void;
  placeholder: string;
}

export const GenericFilter: React.FC<IGenericFilterProps> = ({ handleChange, placeholder }) => {
  return (
    <div className="d-inline-block">
      <Form.Control onChange={handleChange} placeholder={placeholder} />
    </div>
  );
};
