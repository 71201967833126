import { Icon } from '../Icon/Icon';

export const ActiveLink = (props: any) => {
  return (
    <a href={props.path} className={`${props.className} ${props.active}`} {...props}>
      {<Icon icon={props.icon} className="fa-2x me-3" style={{ width: '1em' }}></Icon>}{' '}
      <span className="sidebar-link-title">{props.name}</span>
    </a>
  );
};
