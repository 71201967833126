import { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';

interface DataTablePaginationProps {
  canPreviousPage: any;
  canNextPage: any;
  pageOptions: any;
  nextPage: any;
  previousPage: any;
  gotoPage: any;
  pageIndex: any;
  viewportWidth: number | undefined;
}

export function DataTablePagination({
  canPreviousPage,
  canNextPage,
  pageOptions,
  nextPage,
  previousPage,
  gotoPage,
  pageIndex,
  viewportWidth
}: DataTablePaginationProps) {
  const [visiblePages, setVisiblePages] = useState<any>(false);

  useEffect(() => {
    if (pageOptions) {
      const newOptions: any = [...pageOptions];
      const notMobile = viewportWidth ? viewportWidth > 767 : undefined;
      const pageNumber = notMobile ? 5 : 2;

      if (pageIndex > (notMobile ? 4 : 2)) {
        setVisiblePages(newOptions.splice(pageIndex - (notMobile ? 2 : 1), pageNumber));
      } else {
        setVisiblePages(newOptions.splice(0, pageNumber));
      }
    }
  }, [pageOptions, pageIndex, viewportWidth]);

  return (
    <Pagination className="ms-md-auto mb-0">
      {pageIndex > 4 && <Pagination.First onClick={() => gotoPage(0)} />}
      {canPreviousPage && <Pagination.Prev onClick={() => previousPage()} />}
      {visiblePages &&
        visiblePages.map((page: any) => (
          <Pagination.Item
            key={page}
            onClick={() => gotoPage(page)}
            className={pageIndex === page ? 'active' : ''}
          >
            {page + 1}
          </Pagination.Item>
        ))}

      {canNextPage && <Pagination.Next onClick={() => nextPage()} />}
      {pageIndex < pageOptions.length - 1 && (
        <Pagination.Last onClick={() => gotoPage(pageOptions.length - 1)} />
      )}
    </Pagination>
  );
}
