import React from 'react';
import { Badge, Dropdown } from 'react-bootstrap';

export interface BadgeDropdownProps {
  /**
   * List of items present in the dropdown
   */
  items: Array<BadgeDropdownItem>;

  /**
   * Selected item on the dropdown
   */
  value: BadgeDropdownItem;

  /**
   * A callback fired when a menu item is selected.
   * @param item The item selected
   * @returns
   */
  onSelect?: (item: BadgeDropdownItem) => void;
}

export interface BadgeDropdownItem {
  name: string;
  type: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';
}

type CustomToggleProps = {
  children?: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
  variant: string;
};

const CustomToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
    <a
      href="#action"
      ref={ref}
      onClick={(event) => {
        event.preventDefault();
        props.onClick(event);
      }}
    >
      <Badge bg={props.variant}>
        {props.children}
        <span style={{ paddingLeft: '5px' }}>&#x25bc;</span>
      </Badge>
    </a>
  )
);

/**
 * Component to display the badge dropdown
 * @param props
 * @returns
 */
export const BadgeDropdown = (props: BadgeDropdownProps) => {
  const { items, value } = props;

  const onSelect = (key: string | null) => {
    if (key && props.onSelect) {
      const selectedItem = items[Number(key)];

      if (selectedItem) props.onSelect(selectedItem);
    }
  };

  return (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle variant={value.type} id="dropdown-basic" as={CustomToggle}>
        {value.name}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {items.map((item: BadgeDropdownItem, index) => {
          return (
            <Dropdown.Item key={`${index}-${item.name}`} eventKey={index} className='custom-dropdown-item'>
              <Badge bg={item.type}>{item.name}</Badge>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
