import React, {
  ChangeEvent,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Grid } from './utilities/Grid';
import { FileLine } from './utilities/FileLine';
import { IAction } from './interfaces/IAction';
import { VersionGrid } from './utilities/VersionGrid';
import { IFile } from './interfaces/IFile';
import { buildArrayStructure } from './utilities/TimelineUtilities';
import { ViewBox } from './interfaces/ISpace';
import { TimelineConfig } from './utilities/TimelineConfig';
import { Form } from 'react-bootstrap';
import { t } from 'i18next';
import { IVersion } from './interfaces/IVersion';
import { ISelectionMode } from './interfaces/ISelectionMode';

export interface TimelineProps {
  source: Array<IFile>;
  isGridVisible: boolean;
  actions: Array<IAction>;
  handleNewVersion: Function;
  className?: string;
  callbackVersion: Function;
  selectionMode?: ISelectionMode | null;
  callbackSelectionMode: Function;
  callbackTitle?: (file: IFile, value: string) => void;
}
interface ContextTimeline {
  actions: Array<IAction>;
  handleNewVersion: Function;
  actionMenu: IVersion | null;
  setActionMenu: Function;
  selectionMode: ISelectionMode | null | undefined;
  callbackTitle?: (file: IFile, value: string) => void;
}

export const TimelineContext = createContext<ContextTimeline>({
  handleNewVersion: (arg: IFile) => {
    return arg;
  },
  actions: [],
  actionMenu: null,
  setActionMenu: () => {},
  selectionMode: null,
  callbackTitle: undefined
});

let onDrag = false;
let offsetPoint = new DOMPoint();

export const Timeline: React.FC<TimelineProps> = ({
  source,
  isGridVisible = false,
  actions,
  handleNewVersion,
  className,
  callbackVersion,
  selectionMode,
  callbackSelectionMode,
  callbackTitle
}) => {
  const [data, setData] = useState<Array<any>>([]);
  const [actionMenu, setActionMenu] = useState<IVersion | null>(null);

  const filterIfcModels = (value: string) => {
    const filterValue = value.toLowerCase();
    return source.filter((item) => {
      return item.name.toLowerCase().indexOf(filterValue) > -1 ? true : false;
    });
  };

  useEffect(() => {
    setData(source);
  }, [source]);

  const gridStructure = useMemo(() => buildArrayStructure(source), [source]);

  const [viewBox, setViewbox] = useState<ViewBox>({
    x: 0,
    y: 0,
    width: 1000,
    height: 700
  });

  const refSvg = useRef<SVGSVGElement>(null);

  // take the first point of drag
  const startDrag = useCallback((event: MouseEvent) => {
    event.preventDefault();

    onDrag = true;
    refSvg.current?.classList.add('grabbing');

    offsetPoint.x = event.clientX;

    const mouseup = () => {
      onDrag = false;
      refSvg.current?.classList.remove('grabbing');
      document.removeEventListener('mouseup', mouseup);
    };

    document.addEventListener('mouseup', mouseup);
  }, []);

  // take the first point of drag
  const moveDrag = useCallback((event: MouseEvent) => {
    event.preventDefault();

    if (onDrag) {
      let matrix = refSvg.current?.getScreenCTM()?.inverse();
      let point = new DOMPoint(event.clientX);

      let cursor = point.matrixTransform(matrix);
      let offsetCursor = offsetPoint.matrixTransform(matrix);

      // avoid dependency and pile on the stack the calculation
      setViewbox((viewBox) => ({
        x: Math.max(0, viewBox.x - (cursor.x - offsetCursor.x)),
        y: viewBox.y,
        width: viewBox.width,
        height: viewBox.height
      }));
      //we move the drag ref after moving the viewBox
      offsetPoint.x = event.clientX;
    }
  }, []);

  return (
    <>
      {selectionMode && selectionMode.activate && selectionMode.message && selectionMode.cancel ? (
        <div className="position-sticky w-100 bg-secondary text-center mb-2 p-1" style={{ top: 0 }}>
          {selectionMode.message} {selectionMode.cancel}{' '}
        </div>
      ) : null}
      <div className="position-sticky w-25 float-end" style={{ top: '50px', padding: '0 2rem' }}>
        <Form.Control
          type="text"
          placeholder={t('storybook.datatable.placeholder') ?? ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setData(filterIfcModels((event.target as HTMLInputElement).value))
          }
        />
      </div>
      <TimelineContext.Provider
        value={useMemo(
          () => ({
            actions,
            handleNewVersion,
            actionMenu,
            setActionMenu,
            selectionMode,
            callbackTitle
          }),
          [actions, handleNewVersion, actionMenu, selectionMode, callbackTitle]
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={className ? className : ''}
          preserveAspectRatio="xMinYMin slice"
          ref={refSvg}
          viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`}
          width={viewBox.width * TimelineConfig.viewBoxAspectRation}
          height={viewBox.height * TimelineConfig.viewBoxAspectRation}
          /* @ts-ignore => does not understand the link to primitive event */
          onMouseDown={startDrag}
          /* @ts-ignore => does not understand the link to primitive event */
          onMouseMove={moveDrag}
          onClick={(event) => {
            if (event.button === 0 && !(event.target as any).closest('g .version')) {
              setActionMenu(null);
            }
          }}
          onContextMenu={(event) => {
            event.preventDefault();
            if (!selectionMode?.activate) {
              if (event.button === 2) {
                const versionElementHtml = (event.target as any).closest('g .version');
                if (versionElementHtml && versionElementHtml.dataset) {
                  setActionMenu({
                    version: Number(versionElementHtml.dataset.versionId),
                    ifcFileId: Number(versionElementHtml.dataset.fileId),
                    creationDate: '',
                    evaluationStatus: 'Unknown'
                  });
                } else {
                  setActionMenu(null);
                }
              } else {
                setActionMenu(null);
              }
            }
          }}
        >
          {isGridVisible && <Grid x={20} y={TimelineConfig.timelinePadding} />}
          <g className="fileLanes">
            <VersionGrid
              structure={gridStructure}
              transform={`translate(${TimelineConfig.timelinePadding} 5)`}
            />
            {data.map((file: IFile, index: number) => {
              let yPosition = 85 + index * 40;
              return (
                <>
                  <FileLine
                    key={index}
                    file={file}
                    transform={`translate(${TimelineConfig.timelinePadding} ${yPosition})`}
                    versionStructure={gridStructure}
                    viewBoxOffset={viewBox.x}
                    callbackVersion={callbackVersion}
                    callbackSelectionMode={callbackSelectionMode}
                  />
                </>
              );
            })}
          </g>
        </svg>
      </TimelineContext.Provider>
    </>
  );
};
