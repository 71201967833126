import { Spinner } from 'react-bootstrap';

export const DataTableLoader = () => {
  return (
    <Spinner
      animation={'border'}
      className="position-absolute start-50 top-50"
      variant="warning"
    />
  );
};
