import { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';

export interface CustomModalProps {
  show: boolean;
  setShow: Function;
  onAction: Function;
  actionName: string;
  title: string;
  type: 'success' | 'warning' | 'danger' | 'info' | 'primary';
  size?: 'sm' | 'lg' | 'xl';
  disabled?: boolean;
  cancelButton?: string;
  onClose?: Function;
}

export const CustomModal: FC<CustomModalProps> = ({
  size,
  show,
  setShow,
  title,
  actionName,
  type,
  cancelButton,
  disabled,
  children,
  onAction,
  onClose
}) => {
  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size={size}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ lineHeight: '21.6px' }}>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {cancelButton}
          </Button>
          <Button variant={type} onClick={() => onAction()} disabled={disabled ? true : false}>
            {actionName}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
