import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import {
  useTable,
  usePagination,
  useSortBy,
  useRowState,
  useGlobalFilter,
  Column
} from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useWindowSize } from '../../hooks/useWindowSize';
import { DataTablePagination } from './DataTablePagination';
import { useTranslation } from '../../hooks/useTranslation';
import { dictionary } from '../../data/dictionnary';
import { PerPage } from './PerPage';
import { DataTableLoader } from './DataTableLoader';
export interface DataTableProps<T extends Object = {}> {
  columns: Array<Column<T>>;
  items: Array<T>;
  cellClassname?: string;
  hideHeader?: boolean;
  itemsPerPage?: number;
  striped?: boolean;
  perPageOptions?: Array<PageNumber>;
  lang: string;
  children?: React.ReactNode;
  labelPage?: string;
  loading?: boolean;
  genericFilterValue?: string;
}
export interface PageNumber {
  label: number;
  value: number;
}

let sizeCol: number;

export const DataTable = (props: DataTableProps) => {
  const {
    columns,
    items,
    cellClassname,
    hideHeader,
    striped,
    itemsPerPage,
    lang,
    children,
    perPageOptions = [
      { label: 10, value: 10 },
      { label: 20, value: 20 },
      { label: 30, value: 30 }
    ],
    labelPage = 'Number of result',
    loading = false,
    genericFilterValue
  } = props;
  const { translate, setLanguage } = useTranslation(dictionary);
  const source = React.useMemo(() => items, [items]);

  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: source,
      initialState: {
        pageIndex: 0,
        pageSize: itemsPerPage || 5
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowState
  );

  const size = useWindowSize();

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  useEffect(() => {
    if(genericFilterValue){
      setGlobalFilter(genericFilterValue)
    }
  }, [genericFilterValue])

  return (
    <React.Fragment>
      <div className={`dataTable-top`}>
        {children}
        <PerPage
          perPageOptions={perPageOptions}
          labelPage={labelPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </div>
      <Table hover {...getTableProps()} className="align-middle mb-0" striped={striped}>
        <thead className={hideHeader ? 'd-none' : ''}>
          {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
              {headerGroup.headers.map((column: any, columnIndex: number) => {
                sizeCol = headerGroup.headers.length;
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="py-4"
                    key={columnIndex}
                  >
                    <span className="d-flex align-items-center text-transform-none ">
                      {column.render('Header')}
                      {!column.disableSortBy && (
                        <span className="d-grid ms-auto">
                          <FontAwesomeIcon
                            icon={faCaretUp}
                            className={`ms-1 ${!column.isSortedDesc ? 'opacity-6' : ''}`}
                            size="sm"
                          />
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className={`ms-1 ${
                              column.isSorted && !column.isSortedDesc ? '' : 'opacity-6'
                            }`}
                            size="sm"
                          />
                        </span>
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="position-relative border-top-0">
          {loading && <DataTableLoader />}
          {page &&
            page.map((row: any, i: number) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i} className={`${loading && 'opacity-25'}`}>
                  {row.cells.map((cell: any, cellIndex: number) => {
                    return (
                      <td {...cell.getCellProps()} className={cellClassname ?? ''} key={cellIndex}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          {(!page || page.length === 0) && lang === ' en' && (
            <tr>
              <td colSpan={sizeCol}>{translate('no_data_found')}</td>
            </tr>
          )}
          {(!page || page.length === 0) && lang === 'fr' && (
            <tr>
              <td colSpan={sizeCol}>{translate('no_data_found')}</td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="dataTable-bottom align-items-center d-flex">
        {lang === 'en' && (
          <div className="flex-shrink-0 mb-2 mb-md-0 me-auto">
            Showing page {pageIndex + 1} of {pageCount}
          </div>
        )}
        {lang === 'fr' && (
          <div className="flex-shrink-0 mb-2 mb-md-0 me-auto">
            Affichage des pages {pageIndex + 1} sur {pageCount}
          </div>
        )}
        {pageOptions.length > 1 && (
          <DataTablePagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            viewportWidth={size?.width}
          />
        )}
      </div>
    </React.Fragment>
  );
};
